import gsap from "gsap";

export default {
    name: "default",
    leave({ current, next }) {
        const onComplete = this.async();

        gsap.to(current.container, {
            opacity: 0,
            duration: 0.75,
            onComplete: () => {
                current.container.style.position = "fixed";
                onComplete();
            },
        });
    },
    enter({ next }) {
        // const onComplete = this.async();
        // next.container.classList.add("transitioning");
        gsap.set(next.container, {
            opacity: 0,
        });
        gsap.to(next.container, {
            opacity: 1,
            duration: 0.75,
            delay: 0.75,
            onComplete: () => {
                // onComplete();
                // next.container.classList.add("transitioning");
            },
        });
    },
};
