import { primaryInput } from "detect-it";
import Flickity from "flickity";
import polylinearScale from "polylinear-scale";

export default {
    targets: [
        "slider",
        "slide",
        "slideImage",
        "controlLayer",
        "leftControl",
        "rightControl",
    ],
    start() {
        this.slider = new Flickity(this.sliderTarget, {
            cellAlign: this.$state.cellAlign || "left",
            pageDots: false,
            prevNextButtons: false,
            // contain: true,
            draggable: primaryInput == "touch",
        });

        this.slider.on("change", this.onSlideChange);
        this.onSlideChange();

        if (!this.$state.skipTransform || !(window.innerWidth >= 1024)) {
            this.createScales();
            this.onSliderScroll();
            this.slider.on("scroll", this.onSliderScroll);
            this.slider.on("resize", this.onSliderResize);
        }

        if (primaryInput == "touch" && this.controlLayerTarget) {
            this.controlLayerTarget.style.display = "none";
        }
    },
    prevClick() {
        this.slider.previous();
    },
    nextClick() {
        this.slider.next();
    },
    imageLoaded() {
        this.slider.resize();
    },
    createScales() {
        this.slider.slides.forEach((slide) => {
            slide.translateScale = polylinearScale(
                [-slide.outerWidth, 0, slide.outerWidth, slide.outerWidth * 2],
                [-1, 0, 1, 1.5],
                true
            );
        });
    },
    onSliderResize() {
        this.createScales();
        this.onSliderScroll();
    },
    onSliderScroll(event) {
        this.slider.slides.forEach((slide, i) => {
            if (!this.$state.skipTransform) {
                const translateX = slide.translateScale(
                    this.slider.x + slide.target
                );

                this.slideImageTargets[i].style.transform = `translateX(${
                    window.innerWidth >= 1024
                        ? window.innerWidth * 0.18 * translateX
                        : window.innerWidth * 0.1 * translateX
                }px)`;
            }
        });
    },
    onSlideChange() {
        if (!this.leftControlTarget || !this.rightControlTarget) return;

        if (this.slider.selectedIndex == 0)
            this.leftControlTarget.removeAttribute("data-cursor");
        else if (this.slider.selectedIndex == this.slider.slides.length - 1)
            this.rightControlTarget.removeAttribute("data-cursor");
        else {
            this.leftControlTarget.setAttribute("data-cursor", "leftArrow");
            this.rightControlTarget.setAttribute("data-cursor", "rightArrow");
        }
    },
    stop() {
        this.slider.destroy();
    },
};
