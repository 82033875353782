import gsap from "gsap";
import Flip from "gsap/Flip";
import { getScrollBarWidth } from "../lib/helpers";
gsap.registerPlugin(Flip);

export default {
    name: "exhibitions-to-exhibition",
    from: {
        namespace: ["exhibitions"],
    },
    to: {
        namespace: ["exhibition"],
    },
    leave(data) {
        const onComplete = this.async();
        const isMobile = window.innerWidth < 1024;

        if (isMobile) {
            gsap.to(data.current.container, {
                opacity: 0,
                onComplete,
            });
        } else {
            const image =
                data.trigger != "barba"
                    ? data.trigger.querySelector("img")
                    : data.current.container
                          .querySelector(`a[href="${data.next.url.path}"`)
                          .querySelector("img");
            const { top, left, width, height } = image.getBoundingClientRect();
            const reverseRatio =
                width / height > window.innerWidth / window.innerHeight;
            const floatingImage = image.cloneNode();
            floatingImage.classList.add("floating-image-clone");
            document.body.appendChild(floatingImage);
            floatingImage.onload = () => {
                gsap.set(floatingImage, {
                    position: "fixed",
                    left: 0,
                    top: 0,
                    // scale: width / window.innerWidth,
                    scale: 1,
                    transformOrigin: "top left",
                    x: left,
                    y: top,
                    // width: window.innerWidth,
                    width,
                    opacity: 1,
                    zIndex: 49,
                });

                gsap.to(data.current.container, {
                    opacity: 0,
                    onComplete: () => {
                        // reverseRatio == image is wider than the viewport is, relative to the height

                        if (reverseRatio) {
                            const ratio = width / height;
                            const fullWidth = window.innerHeight * ratio;
                            const diff = window.innerWidth - fullWidth;
                            gsap.to(floatingImage, {
                                x: diff * 0.5,
                                y: 0,
                                // scale: 1,
                                scale: window.innerHeight / height,
                                ease: "expo.inOut",
                                duration: 1.5,
                                // duration: 20,
                                onComplete,
                            });
                        } else {
                            const ratio = height / width;
                            const fullHeight = window.innerWidth * ratio;
                            const diff = window.innerHeight - fullHeight;

                            gsap.to(floatingImage, {
                                x: 0,
                                y: diff * 0.5,
                                // scale: 1,
                                scale:
                                    (window.innerWidth - getScrollBarWidth()) /
                                    width,
                                ease: "expo.inOut",
                                duration: 1.5,
                                // duration: 20,
                                onComplete,
                            });
                        }

                        // original
                        // const fullHeight = islandwindow.innerWidth * ratio;
                        // const diff = window.innerHeight - fullHeight;

                        // gsap.to(floatingImage, {
                        //     x: 0,
                        //     y: diff * 0.5,
                        //     // scale: 1,
                        //     scale: window.innerWidth / width,
                        //     ease: "expo.inOut",
                        //     duration: 1.5,
                        //     // duration: 20,
                        //     onComplete,
                        // });
                    },
                });
            };
        }
    },
    enter(data) {
        const floatingImage = document.querySelector(".floating-image-clone");
        const image = data.next.container.querySelector(".image-container img");

        if (image.complete) {
            if (floatingImage) floatingImage.remove();
        } else {
            image.onload = () => {
                if (floatingImage) floatingImage.remove();
            };
        }
    },
};
