import { primaryInput } from "detect-it";
import Flickity from "flickity";
import gsap from "gsap";
import { parse } from "tiny-querystring";
import body from "../lib/body";

export default {
    targets: [
        "name",
        "email",
        "phone",
        "slider",
        "container",
        "overlay",
        "controls",
        "leftControl",
        "rightControl",
    ],
    start() {
        this.$events.on("routing:navigation-after", this.onNavigation);
        this.addListeners();

        if (this.getSlugFromUrl()) {
            this.fetchAndPlace();
        }
    },
    onNavigation() {
        if (this.isVisible) this.close(true);
    },
    startSlider() {
        let slidesHTML = "";

        if (
            this.personData.imageUrl ||
            (!this.personData.imageUrl && !this.personData.obsessions?.length)
        ) {
            slidesHTML += `
                    <div class="team-member-slide">
                        <img src="${
                            this.personData.imageUrl ||
                            "/assets/images/default-avatar.png"
                        }" class="team-member-image" />
                    </div>
                `;
        }
        if (this.personData.quote) {
            slidesHTML += `
            <div class="team-member-slide">
                <p class="team-member-quote">
                    "${this.personData.quote}"
                </p>
            </div>
            `;
        }

        if (this.personData.obsessions?.length) {
            if (window.innerWidth < 1024) {
                slidesHTML +=
                    '<div class="block container font-black text-xl uppercase mb-4 mt-10 lg:hidden">Current Obsessions</div>';
            }
            this.personData.obsessions.forEach((obsession) => {
                slidesHTML += createImageSlide(obsession);
            });
        }

        this.sliderTarget.innerHTML = slidesHTML;

        if (window.innerWidth >= 1024) {
            this.slider = new Flickity(this.sliderTarget, {
                cellAlign: "center",
                draggable: primaryInput == "touch",
                pageDots: false,
                prevNextButtons: false,
                setGallerySize: false,
                selectedAttraction: 0.015,
            });
            this.slider.on("change", this.onSlideChange);
            this.onSlideChange();
            if (this.personData.obsessions?.length > 0) {
                this.controlsTarget.style.display = "flex";
            } else {
                this.controlsTarget.style.display = "none";
            }

            if (primaryInput == "touch" && this.controlsTarget) {
                this.controlsTarget.style.display = "none";
            }
        }
    },
    onSlideChange() {
        if (this.slider.selectedIndex == 0)
            this.leftControlTarget.removeAttribute("data-cursor");
        else if (this.slider.selectedIndex == this.slider.slides.length - 1)
            this.rightControlTarget.removeAttribute("data-cursor");
        else {
            this.leftControlTarget.setAttribute("data-cursor", "leftArrow");
            this.rightControlTarget.setAttribute("data-cursor", "rightArrow");
        }
        if (this.slider.selectedElement.classList.contains("artwork-slide")) {
            gsap.to(this.obsessionsTitleTarget, {
                opacity: 1,
            });
        } else {
            gsap.to(this.obsessionsTitleTarget, {
                opacity: 0,
            });
        }
    },
    async fetchAndPlace(event) {
        console.log(event);
        event?.preventDefault?.();
        const personSlug =
            this.getSlugFromUrl() ||
            event.currentTarget.getAttribute("data-team-member");

        const newUrl = new URLSearchParams(window.location.href);
        newUrl.set("person", personSlug);

        this.$el.style.pointerEvents = "auto";
        gsap.fromTo(this.overlayTarget, { opacity: 0 }, { opacity: 0.75 });

        if (personSlug) {
            window.addEventListener("popstate", this.onPopState);
            // ScrollSmoother.get()?.paused?.(true);
            body.lockScroll(this.sliderTarget);
            const person = await fetch(`/${personSlug}`).then((r) => r.json());

            this.personData = person.page;
            this.changeURL();
            this.startSlider();
            this.placeData();
            gsap.to(this.obsessionsTitleTarget, {
                opacity: 0,
            });
            gsap.to(this.containerTarget, {
                x: 0,
                duration: 1,
                ease: "expo.inOut",
                onComplete: () => {
                    this.isVisible = true;
                },
            });
        }
    },
    changeURL() {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("m", this.personData.slug.current);
        const newRelativePathQuery =
            window.location.pathname + "?" + searchParams.toString();
        history.pushState(null, "", newRelativePathQuery);
    },
    resetURL() {
        window.history.replaceState(
            null,
            "",
            window.location.href.replace(window.location.search, "")
        );
    },
    placeData() {
        this.nameTarget.innerText = this.personData.splitName;
        this.titleTarget.innerText = this.personData.title;

        this.emailTarget.innerText = this.personData.contact?.email || "";
        this.phoneTarget.innerText = this.personData.contact?.phone || "";
    },
    addListeners() {
        const teamMemberLinks = [
            ...document.querySelectorAll("a[data-team-member]"),
        ];
        console.log(teamMemberLinks);
        teamMemberLinks.forEach((link) => {
            link.addEventListener("click", this.fetchAndPlace);
        });
    },
    next() {
        this.slider?.next();
    },
    prev() {
        this.slider?.previous();
    },
    removeListeners() {
        [...document.querySelectorAll("a[data-team-member]")].forEach(
            (link) => {
                link.removeEventListener("click", this.fetchAndPlace);
            }
        );
    },
    closeClick() {
        body.unlockScroll();
        this.close();
    },
    overlayClick(event) {
        if (event.target == event.currentTarget && this.personData) {
            body.unlockScroll();
            this.close();
        }
    },
    getSlugFromUrl() {
        const params = parse(window.location.search.replace("?", ""));

        return params.m;
    },
    onPopState() {
        body.unlockScroll();
        this.close();
    },
    close(fromNavigation) {
        window.removeEventListener("popstate", this.close);
        this.personData = undefined;
        this.resetURL();

        gsap.to(this.overlayTarget, {
            opacity: 0,
            onComplete: () => {
                // ScrollSmoother.get()?.paused?.(false);
                this.$el.style.pointerEvents = "none";
            },
        });
        gsap.to(this.containerTarget, {
            x: "100%",
            ease: "expo.inOut",
            duration: 1,
            onComplete: () => {
                this.isVisible = false;
                this.slider?.destroy?.();
                [...this.sliderTarget.children].forEach((child) => {
                    child.remove();
                });
            },
        });
    },
    stop() {
        this.removeListeners();
    },
};

function createImageSlide({ reference, imageUrl, caption }) {
    return /*html*/ `
   <div class="team-member-slide artwork-slide">
        <a href="${reference.slug.current}">
            <img class="team-member-image" src="${imageUrl}" class="team-member-image" />
            <div class="captions">
                <p class="text-sm leading-[1] uppercase font-black">${
                    reference.artist.name
                }</p>
                <p class="text-sm leading-[1] overflow-hidden pt-1">${
                    reference.title
                }, ${artworkYearSpan(reference)}</p>
            </div>
        </a>
   </div>
   `;
}

const artworkYearSpan = ({ startYear, endYear } = {}) => {
    if (startYear && endYear) return startYear + " - " + endYear;
    if (startYear) return startYear;

    return "undated";
};
