export default {
    targets: [
        "main",
        "menuButton",
        "content",
        "showButton",
        "hideButton",
        "nav",
        "locations",
        "outerContent",
    ],
    start() {
        this.updateActiveStates();
    },
    updateActiveStates() {
        const matchInMenu = this.navigationItemTargets.find(
            (item) => item.getAttribute("href") == window.location.pathname
        );

        if (matchInMenu) {
            this.navigationItemTargets.forEach((item) => {
                if (item == matchInMenu) item.classList.remove("inactive");
                else item.classList.add("inactive");
            });
        } else {
            this.navigationItemTargets.forEach((item) => {
                item.classList.remove("inactive");
            });
        }
    },
    stop() {},
};
