import barba from "@barba/core";
import gsap from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import body from "../lib/body";

export default {
    targets: ["info", "infoContainer", "sliderContainer"],
    start() {
        this.$events.on("artwork:zoom-in", this.onZoomIn);
        this.$events.on("artwork:zoom-out", this.onZoomOut);

        // ScrollSmoother.get()?.paused?.(true);
        // body.lockScroll(document.querySelector(".zoomed-container"));

        // gsap.set(this.infoContainerTarget, {
        //     top: window.innerHeight * 0.9,
        // });

        setTimeout(() => {
            // ScrollSmoother.get()?.paused?.(true);
            // body.lockScroll(document.querySelector(".zoomed-container"));
        }, 500);
    },
    onZoomIn() {
        gsap.to(this.infoContainerTarget, {
            y: 6 * 16, // 6 rem
            duration: 1,
            ease: "expo.inOut",
        });
    },
    onZoomOut() {
        gsap.to(this.infoContainerTarget, {
            y: 0,
            duration: 1,
            delay: 0.65,
            ease: "expo.inOut",
        });
    },
    toOverview() {
        const { pathname } = document.referrer;
        const prevRoute = barba.history.previous;

        if (
            (pathname && pathname.includes("collection")) ||
            (prevRoute && prevRoute.ns == "collection")
        )
            history.back();
        else barba.go("/collection#overview");
    },
    toggleDetails({ currentTarget }) {
        this.showingDetails = !this.showingDetails;

        this.$events.emit("artwork:details-toggled", this.showingDetails);

        if (this.showingDetails) {
            currentTarget.innerHTML =
                'Details (<span class="font-black font-alt">−</span>)';
            this.sliderContainerTarget.style.pointerEvents = "none";
            gsap.to(this.sliderContainerTarget, {
                y: -this.infoContainerTarget.offsetHeight,
                // opacity: 0.4,
                duration: 1.5,
                ease: "expo.inOut",
            });

            gsap.to(this.infoContainerTarget, {
                y: "-100%",
                duration: 1.5,
                delay: 0.05,
                ease: "expo.inOut",
            });
        } else {
            currentTarget.innerHTML =
                'Details (<span class="font-black font-alt">+</span>)';
            this.sliderContainerTarget.style.pointerEvents = "auto";
            gsap.to(this.sliderContainerTarget, {
                y: 0,
                // opacity: 1,
                duration: 1.5,
                delay: 0.05,
                ease: "expo.inOut",
                clearProps: "all",
            });
            gsap.to(this.infoContainerTarget, {
                y: 0,
                duration: 1.5,
                ease: "expo.inOut",
            });
        }
    },
    stop() {},
};
