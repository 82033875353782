import { primaryInput } from "detect-it";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
    targets: ["image", "imagesContainer"],
    start() {
        this.isMobile = window.innerWidth < 1024;
        this.imagesLoaded = this.imageTargets.reduce(
            (loaded, slide) =>
                slide.querySelector("img").complete ? loaded + 1 : loaded,
            0
        );

        if (this.imagesLoaded == this.imageTargets.length) this.launch();
        window.addEventListener("resize", this.onResize);
    },
    imageLoaded() {
        if (this.launched) return;

        if (this.imagesLoaded == this.imageTargets.length - 1) {
            this.launch();
        } else {
            this.imagesLoaded += 1;
        }
    },
    launch() {
        if (this.isMobile) return;
        setTimeout(() => {
            this.setupImageTransformers();
            this.setupTween();
        }, 500);
    },
    setupImageTransformers() {
        this.imageTargets.forEach((slide, i) => {
            // slide 1 progress = 0
            // last slide progress = 1

            slide.transformer = gsap.utils.mapRange(
                -window.innerWidth,
                window.innerWidth,
                -1,
                1
            );
        });
    },
    setupTween() {
        if (this.scrollTween) {
            this.scrollTween.kill();
            this.scrollTween = undefined;
        }
        this.scrollTween = gsap
            .fromTo(
                this.imagesContainerTarget,
                {
                    x: 0,
                },
                {
                    x: () =>
                        -(this.imageTargets.length - 1) * window.innerWidth,
                    ease: "none",
                    onUpdate: () => {
                        this.imageTargets.forEach((slide, i) => {
                            if (window.innerWidth >= 1024) {
                                const { left } = slide.getBoundingClientRect();
                                const amount = gsap.utils.clamp(
                                    -window.innerWidth * 0.5,
                                    window.innerWidth * 0.5,
                                    slide.transformer(left)
                                );

                                gsap.set(
                                    slide.querySelectorAll("img, .caption"),
                                    {
                                        x: -amount * window.innerWidth * 0.5,
                                    }
                                );
                            }
                        });
                    },
                    scrollTrigger: {
                        trigger: this.$el,
                        start: "top top",
                        end: () =>
                            `+=${
                                window.innerWidth * this.imageTargets.length
                            }px`,
                        // end: window.innerWidth * this.imageTargets.length,
                        scrub: true,
                        pin: true,
                        pinSpacing: true,
                        pinReparent: true,
                        invalidateOnRefresh: true,
                    },
                }
            )
            .scrollTrigger.refresh();

        ScrollTrigger.refresh();
    },
    onResize() {
        this.isMobile = window.innerWidth < 1024;
        // this.isMobile = primaryInput == "touch" && window.innerWidth < 1024;
        setTimeout(() => {
            this.launch();
        }, 500);
    },

    stop() {
        window.removeEventListener("resize", this.onResize);
        this.scrollTween.kill();
    },
};
