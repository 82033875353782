import gsap from "gsap";

export default {
    targets: ["image"],
    start() {
        if (this.imageTarget.complete) {
            this.loaded();
        }
    },
    loaded() {
        if (this.timeline) return;

        this.timeline = gsap.timeline({
            scrollTrigger: {
                trigger: this.$el,
                pin: true,
                scrub: true,
                start: "top top",
                end: "+=1500",
                snap: {
                    snapTo: "labels",
                    duration: { min: 0.25, max: 0.5 },
                    ease: "expo.out",
                },
            },
        });

        this.buildTimeline();
    },
    buildTimeline() {
        this.timeline
            .addLabel("start")
            .set(this.imageTarget, {
                transformOrigin: "top center",
                scale: 0.35,
                y: () => {
                    const windowImageDiff =
                        (window.innerHeight - this.$el.offsetHeight * 0.35) *
                        0.5;
                    return windowImageDiff;
                },
                ease: "power2.inOut",
            })
            .to(this.imageTarget, { scale: 1, ease: "power2.inOut", y: 0 })
            .addLabel("end");
    },
    stop() {
        this.scrollTween.kill();
    },
    resize() {
        this.scrollTween.invalidate();
        this.scrollTween.scrollTrigger.refresh();
    },
};
