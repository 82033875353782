import gsap from "gsap";

export default {
    targets: [
        "cursor",
        "leftArrow",
        "rightArrow",
        "minus",
        "plus",
        "cross",
        "text",
    ],
    start() {
        this.$events.on("cursor-change", this.onCursorChange);

        window.addEventListener("mousemove", this.onMouseMove);
    },
    stop() {
        this.$events.off("cursor-change", this.onCursorChange);
    },
    onCursorChange({ type = "default", content } = {}) {
        if (type == "default") {
            document.body.style.cursor = "auto";

            this.switchCursor(this.defaultTarget);
        } else {
            document.body.style.cursor = "none";
            if (type == "leftArrow") this.switchCursor(this.leftArrowTarget);
            if (type == "rightArrow") this.switchCursor(this.rightArrowTarget);
            if (type == "minus") this.switchCursor(this.minusTarget);
            if (type == "plus") this.switchCursor(this.plusTarget);
            if (type == "cross") this.switchCursor(this.crossTarget);
        }
    },
    switchCursor(newCursor) {
        const current = this.cursorTarget.querySelector(".active");

        if (current !== newCursor) {
            current.classList.remove("active");
            newCursor.classList.add("active");
        }
    },
    setText(content) {
        this.textTarget.innerText = content;
    },
    onMouseMove(event) {
        this.$el.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
        const cursorType = event.target.getAttribute("data-cursor");
        const nearestCursorType = event.target
            .closest("[data-cursor]")
            ?.getAttribute?.("data-cursor");

        if (cursorType || nearestCursorType) {
            this.onCursorChange({ type: cursorType || nearestCursorType });
        } else {
            this.onCursorChange({ type: "default" });
        }
    },
    stop() {
        console.log("stopping cursor");
        window.removeEventListener("mousemove", this.onMouseMove);
    },
};
