import gsap from "gsap";

export default {
    targets: ["image"],
    start() {
        this.setX = gsap.quickSetter(this.imageTarget, "x", "px");
        this.setOpacity = gsap.quickSetter(this.imageTarget, "opacity");
        this.$el.addEventListener("mouseenter", this.onMouseEnter);
        this.$el.addEventListener("mousemove", this.onMouseMove);
        this.$el.addEventListener("mouseleave", this.onMouseLeave);
    },
    onMouseEnter(event) {
        this.setOpacity(1);
        this.setX(event.pageX);
    },
    onMouseMove(event) {
        this.setX(event.pageX);
    },
    onMouseLeave() {
        this.setOpacity(0);
    },
    onClick() {
        this.$el.removeEventListener("mouseenter", this.onMouseEnter);
        this.$el.removeEventListener("mousemove", this.onMouseMove);
        this.$el.removeEventListener("mouseleave", this.onMouseLeave);
    },
    stop() {
        this.$el.removeEventListener("mouseenter", this.onMouseEnter);
        this.$el.removeEventListener("mousemove", this.onMouseMove);
        this.$el.removeEventListener("mouseleave", this.onMouseLeave);
    },
};
