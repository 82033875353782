export default class Events {
  listeners = {};

  on(event, callback) {
    if (this.listeners[event]) {
      this.listeners[event].push(callback);
    } else {
      this.listeners[event] = [callback];
    }

    return () => this.off(event, callback);
  }

  off(event, callback) {
    if (this.listeners[event] && this.listeners.includes(callback)) {
      this.listeners[event].slice(this.listeners[event].indexOf(callback), 1);
    }
  }

  emit(event, payload) {
    if (this.listeners[event]?.length) {
      this.listeners[event].forEach((callback) => callback(payload));
    }
  }

  once(event, callback) {
    this.on(event, (...args) => {
      callback(...args);
      this.off(event, callback);
    });
  }
}
