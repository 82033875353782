import { id } from "./utils";

export default function createComponent({ definition, name, $el, $events }) {
    const baseInstance = {
        definition,
        name,
        $el,
        $id: id(),
        $events,
    };

    let stateCache;

    const instance = new Proxy(baseInstance, {
        get(target, prop, receiver) {
            if (prop.endsWith("Target")) {
                const targetLabel = prop.substring(0, prop.indexOf("Target"));
                const targetElement = target.$el.querySelector(
                    `[data-${name}-target="${targetLabel}"`
                );

                return targetElement;
            }

            if (prop.endsWith("Targets")) {
                const targetLabel = prop.substring(0, prop.indexOf("Targets"));
                const targetElements = target.$el.querySelectorAll(
                    `[data-${name}-target="${targetLabel}"`
                );

                return [...targetElements];
            }

            if (prop == "$state") {
                const rawState = $el.getAttribute(`data-${name}-state`);

                if (!rawState) return {};

                try {
                    if (!stateCache) {
                        const state = JSON.parse(rawState);
                        stateCache = state;
                    }
                    return stateCache;
                } catch (e) {
                    return {};
                }
            }

            return target[prop];
        },
    });

    // Bind functions to instance
    const keys = Reflect.ownKeys(instance.definition).filter(
        (key) => typeof instance.definition[key] == "function"
    );

    for (let key of keys) {
        if (!instance[key])
            instance[key] = instance.definition[key].bind(instance);
    }

    return instance;
}
