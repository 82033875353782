import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

/**
 * this.collectionTarget
 */
export default {
    targets: [
        "list",
        "gallery",
        "listToggle",
        "galleryToggle",
        "galleryItem",
        "listItem",
        "filterCount",
    ],
    start() {
        gsap.fromTo(
            this.artworkTargets,
            { opacity: 0, y: 50 },
            { opacity: 1, duration: 1, delay: 1, stagger: 0.05, y: 0 }
        );

        this.$events.on("collection:filter-update", this.onFilterUpdate);
    },
    toggleGallery(event) {
        this.listTarget.style.display = "none";
        this.galleryTarget.style.display = "grid";
        event.target.classList.remove("opacity-10");
        this.listToggleTarget.classList.add("opacity-10");

        ScrollTrigger.refresh();
    },
    toggleList(event) {
        this.galleryTarget.style.display = "none";
        this.listTarget.style.display = "block";
        event.target.classList.remove("opacity-10");
        this.galleryToggleTarget.classList.add("opacity-10");

        ScrollTrigger.refresh();
    },
    onFilterUpdate(filters) {
        const visibleArtworks = this.$state.artworks
            .filter((artwork) => {
                if (filters.mediums.length) {
                    if (!filters.mediums.includes(artwork.medium.title))
                        return false;
                }
                if (filters.artists.length) {
                    if (!filters.artists.includes(artwork.artist.name))
                        return false;
                }
                if (filters.viewable.length) {
                    console.log(filters.viewable);
                    if (
                        !filters.viewable.includes(
                            artwork.currentLocation?.title
                        )
                    )
                        return false;
                }

                return true;
            })
            .map((artwork) => artwork._id);

        this.updateFilterCount(filters, visibleArtworks);
        this.updateLists(visibleArtworks);
    },
    updateFilterCount(filters, artworks) {
        const filterCount = Object.values(filters).reduce(
            (count, current) => count + current.length,
            0
        );
        this.filterCountTarget.innerHTML = `
            (${filterCount} filters ${artworks.length} artworks)
        `;
    },
    updateLists(visibleArtworks) {
        if (visibleArtworks) {
            this.galleryItemTargets.forEach((item) => {
                if (visibleArtworks.includes(item.dataset.id))
                    item.classList.remove("hidden");
                else item.classList.add("hidden");
            });

            this.listItemTargets.forEach((item) => {
                if (visibleArtworks.includes(item.dataset.id))
                    item.classList.remove("hidden");
                else item.classList.add("hidden");
            });
        } else {
            this.galleryItemTargets.forEach((item) =>
                item.classList.remove("hidden")
            );
            this.listItemTargets.forEach((item) =>
                item.classList.remove("hidden")
            );
        }

        ScrollTrigger.refresh();
    },
    stop() {},
};
