import ScrollTrigger from "gsap/ScrollTrigger";
import gsap, { ScrollToPlugin } from "gsap/all";
import body from "../lib/body";

gsap.registerPlugin(ScrollToPlugin);

export default {
    start() {
        window.addEventListener("resize", this.onResize);
        setTimeout(() => {
            this.scrollIn();
        });
    },
    onResize() {
        // ScrollSmoother.get().scrollTo(0);
        ScrollTrigger.refresh();
    },
    scrollIn() {
        if (window.scrollY < 200) {
            gsap.to(window, {
                scrollTo: {
                    y: 200,
                    autoKill: false,
                },
                ease: "expo.inOut",
                duration: 1,
                delay: 0.5,
            });
        }
    },
    stop() {
        window.removeEventListener("resize", this.onResize);
    },
};
