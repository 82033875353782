import createComponent from "./component";
import Events from "./events";
import {
    bindActionToNode,
    parseActionDefinition,
    parseComponentDefinition,
} from "./utils";

export default class App {
    constructor({ components, debug = false }) {
        this.debug = debug;
        this.components = components;
        this.globalEvents = new Events();
        this.globalState = {};
        this.$liveComponents = Object.keys(this.components).reduce(
            (map, name) => ({ ...map, [name]: {} }),
            {}
        );
        this.$cleanupTasks = {};

        if (debug) window._sprinkles = this;

        this.boot();
        this.observeComponents();
    }

    setState(stateSetter = (state) => state) {
        this.globalState = stateSetter(this.globalState);
    }

    boot(container = document.body) {
        this.container = container;

        const elementsWithComponents = [
            ...container.querySelectorAll("[data-component]"),
        ];

        for (let el of elementsWithComponents) {
            const components = parseComponentDefinition(el);

            for (let componentName of components) {
                if (
                    this.components[componentName] &&
                    !el.hasAttribute(`data-${componentName}-id`)
                ) {
                    const instance = createComponent({
                        definition: this.components[componentName],
                        name: componentName,
                        $el: el,
                        $events: this.globalEvents,
                    });
                    el.setAttribute(`data-${componentName}-id`, instance.$id);

                    this.$cleanupTasks[instance.$id] = {
                        unbindActions: [],
                    };

                    this.bindActions({
                        node: el,
                        componentName,
                        componentInstance: instance,
                    });

                    this.$liveComponents[componentName][instance.$id] =
                        instance;
                }
            }
        }

        this.startInstances();
    }

    // data-action="click->collection#toggleGallery"

    // data-component="collection" data-action="click->collection#begin"
    bindActions({ node, componentName, componentInstance }) {
        const targetsWithActions = [...node.querySelectorAll(`[data-action]`)];

        if (node.matches(`[data-action]`)) targetsWithActions.push(node);

        for (let target of targetsWithActions) {
            const actionDefintion = target.dataset.action;
            const actions = actionDefintion.includes(" ")
                ? actionDefintion.split(" ").map(parseActionDefinition)
                : [parseActionDefinition(actionDefintion)];

            for (let action of actions) {
                if (action.component == componentName) {
                    const unbind = bindActionToNode({
                        node: target,
                        event: action.event,
                        context: componentInstance,
                        callback: componentInstance.definition[action.method],
                    });

                    if (unbind) {
                        this.$cleanupTasks[componentInstance.$id][
                            "unbindActions"
                        ].push(unbind);
                    }
                }
            }
        }
    }

    startInstances() {
        Object.values(this.$liveComponents).forEach((instances) => {
            Object.values(instances).forEach((instance) => {
                if (!instance._started) {
                    instance.start?.();
                    instance._started = true;
                    if (this.debug)
                        console.log(
                            "Starting instance of ",
                            instance.name,
                            instance
                        );
                }
            });
        });
    }

    observeComponents() {
        const config = { childList: true, subtree: true };
        this.observer = new MutationObserver(this.onMutation.bind(this));
        this.observer.observe(this.container, config);
    }

    onMutation(mutationList, observer) {
        for (const mutation of mutationList) {
            if (mutation.type === "childList") {
                const removedComponents = [...mutation.removedNodes]
                    .filter((node) => node.querySelectorAll)
                    .map((node) => {
                        const components = this.componentsInNode(node);
                        if (components.length && this.debug) {
                            console.log("Removing components", {
                                removedContainer: node,
                                components,
                            });
                        }

                        return components;
                    })
                    .flat();

                removedComponents.forEach(this.destroyComponent.bind(this));

                [...mutation.addedNodes]
                    .filter((node) => node.querySelectorAll)
                    .forEach((node) => this.boot(node));
            }
        }
    }

    componentsInNode(node) {
        return Object.values(this.$liveComponents)
            .map((c) => Object.values(c))
            .flat()
            .filter((component) => {
                if (node.contains(component.$el)) return component;
            });
    }

    destroy() {
        for (let instanceId in this.$cleanupTasks) {
            this.$cleanupTasks[instanceId].unbindActions.forEach((u) => u());
        }

        for (let componentType in this.$liveComponents) {
            for (let instance in this.$liveComponents[componentType]) {
                instance.stop?.();
            }
        }
    }

    destroyComponent(instance) {
        this.$cleanupTasks[instance.$id].unbindActions.forEach((u) => u());
        instance.stop?.();
        delete this.$liveComponents[instance.name][instance.$id];
        if (this.debug)
            console.log(
                `Destroyed component of type ${instance.name}`,
                instance
            );
    }

    refresh({ destroyIn } = {}) {
        // destroy components not living anymore
        // optionally if destroyIn is passed, only destroy the ones in that container (used for unmounting elements still in DOM but in a specific place)
        // call this.boot() to find the new components
        // this.destroy();
        // this.boot();
    }

    emit(...args) {
        return this.globalEvents.emit(...args);
    }

    on(...args) {
        return this.globalEvents.on(...args);
    }

    once(...args) {
        return this.globalEvents.once(...args);
    }
}
