import { primaryInput } from "detect-it";
import Flickity from "flickity";

export default {
    targets: ["slide", "slider", "progress", "leftControl", "rightControl"],
    start() {
        this.slider = new Flickity(this.sliderTarget, {
            contain: true,
            resize: true,
            wrapAround: false,
            prevNextButtons: false,
            pageDots: false,
            draggable: primaryInput == "touch",
            // setGallerySize: false,
            selectedAttraction: 0.015,
        });

        this.slider.on("change", this.onSlideChange);
        this.onSlideChange();
    },
    onSlideChange() {
        if (this.slider.selectedIndex == 0)
            this.leftControlTarget.removeAttribute("data-cursor");
        else if (this.slider.selectedIndex == this.slider.slides.length - 1)
            this.rightControlTarget.removeAttribute("data-cursor");
        else {
            this.leftControlTarget.setAttribute("data-cursor", "leftArrow");
            this.rightControlTarget.setAttribute("data-cursor", "rightArrow");
        }

        const current =
            this.slider.selectedIndex > 9
                ? this.slider.selectedIndex + 1
                : "0" + (this.slider.selectedIndex + 1);
        const total =
            this.slider.slides.length > 9
                ? this.slider.slides.length
                : "0" + this.slider.slides.length;
        this.progressTarget.innerText = `${current}/${total}`;
    },
    prev() {
        this.slider.previous();
    },
    next() {
        this.slider.next();
    },
    stop() {
        this.slider.destroy();
    },
};
