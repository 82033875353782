import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default {
  targets: ["textContent", "readMore"],
  reveal() {
    if (this.revealed) return;
    this.revealed = true;

    gsap.set(this.textContentTarget, { height: "auto" });
    gsap.to(this.textContentTarget, { opacity: 1 });
    gsap.from(this.textContentTarget, {
      height: 0,
      y: -this.readMoreTarget.offsetHeight,
      ease: "expo.out",
      duration: 0.75,
      onComplete: () => ScrollTrigger.refresh(),
    });

    gsap.to(this.readMoreTarget, { opacity: 0, pointerEvents: "none" });

    // gsap.to(this.readMoreTarget, {
    //   height: 0,
    //   opacity: 0,
    //   ease: "circ.out",
    //   duration: 0.75,
    // });
  },
};
