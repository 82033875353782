export function parseActionDefinition(actionString = "") {
    // click->hello#greet

    const [event, effect] = actionString.split("->");
    if (!event || !effect) return null;

    const [component, method] = effect.split("#");
    if (!component || !method) return null;

    return {
        event,
        component,
        method,
    };
}

/**
 * const unbind = bindActionToNode({
 *      node: element,
 *      event: 'click',
 *      context: componentContext,
 *      callback: function myHandler() { }
 * })
 *
 * To remove action, call unbind returned by this function
 * unbind()
 *
 * @param {node, evebnt, context, callback} actionDefintion
 * @returns handlerRemoverFunction
 */
export function bindActionToNode({ node, event, context, callback } = {}) {
    if (!node || !event || !context || !callback) return null;
    const handler = callback.bind(context);
    node.addEventListener(event, handler);

    return () => node.removeEventListener(event, handler);
}

export function parseComponentDefinition(node) {
    const definition = node.dataset.component;
    const components = definition.includes(" ")
        ? definition.split(" ")
        : [definition];

    return components;
}

export function getNearestComponentRoot(node, component = null) {
    if (!node) return null;

    if (component) {
        const componentElement = node.nearest(
            `[data-component*="${component}"`
        );
        return componentElemenet
            ? {
                  root: componentElement,
              }
            : null;
    } else {
        const componentElement = node.nearest("[data-component]");
        const components = parseComponentDefinition(componentElement);

        return {
            root: componentElement,
            components,
        };
    }
}

export function id() {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substring(2);

    return head + tail;
}
