import xor from "lodash/xor";

export default {
    targets: ["container", "menuButton", "filterMenu"],
    start() {
        this.filters = {
            artists: [],
            viewable: [],
            mediums: [],
        };
    },
    toggleMenu({ currentTarget }) {
        this.menuButtonTargets.forEach((button) =>
            button.classList.add("opacity-40")
        );
        currentTarget.classList.remove("opacity-40");

        const menu = currentTarget.getAttribute("data-menu");

        const menuContainer = this.containerTarget.querySelector(
            `.${menu}-menu`
        );

        this.filtersMenuTargets.forEach((m) => m.classList.add("hidden"));
        menuContainer.classList.remove("hidden");
    },
    toggleFilter({ currentTarget }) {
        const filter = currentTarget.dataset.filter;
        this.filters[filter] = xor(this.filters[filter], [
            currentTarget.dataset.id,
        ]);

        this.render();
    },
    render() {
        // toggle visibility on all list-items and grid-items
        // that match filters
        this.updateFilterView();
        this.$events.emit("collection:filter-update", this.filters);
    },
    updateFilterView() {
        Object.keys(this.filters).forEach((filterKey) => {
            const selected = this.filters[filterKey];

            const filterButtons = [
                ...this.containerTarget.querySelectorAll(
                    `[data-filter="${filterKey}"]`
                ),
            ];

            if (selected.length > 0) {
                filterButtons.forEach((button) =>
                    selected.includes(button.dataset.id)
                        ? button.classList.remove("opacity-40")
                        : button.classList.add("opacity-40")
                );
            } else {
                filterButtons.forEach((filter) =>
                    filter.classList.remove("opacity-40")
                );
            }
        });
    },
    toggleVisible({ currentTarget }) {
        this.containerTarget.classList.toggle("hidden");

        if (this.containerTarget.classList.contains("hidden")) {
            currentTarget.innerHTML =
                'Filters (<span class="font-alt font-black inline-flex items-center justify-center text-center w-3">+</span>)';
        } else {
            currentTarget.innerHTML =
                'Filters (<span class="font-alt font-black inline-flex items-center justify-center text-center w-3">−</span>)';
        }
    },
};
