import gsap from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import shuffle from "lodash/shuffle";
import body from "../lib/body";

export default {
    targets: ["exhibition", "artist", "artwork", "publication", "hoverLink"],
    start() {
        this.currentIndex = 0;
        this.animationItems = shuffle(this.getFloatingItems());
        this.enableAnimations = true;

        window.addEventListener("resize", this.onResize);
        this.onResize();
    },
    startAnimation() {
        if (this.currentTween) this.currentTween.kill();
        if (this.pausedAnimation) return;
        const target = this.animationItems[this.currentIndex];
        const linkTarget = this.$el.querySelector(
            `[data-popup=${target.getAttribute("data-home-target")}`
        );

        this.currentTween = gsap.to(target, {
            opacity: 1,
            duration: 0.5,
            onStart: () => {
                gsap.to(linkTarget, {
                    opacity: 0.25,
                    duration: 0.5,
                });
            },
            onComplete: () => {
                gsap.to(linkTarget, {
                    opacity: 1,
                    duration: 0.5,
                    delay: 1,
                });
                this.currentTween = gsap.to(target, {
                    opacity: 0,
                    delay: 1,
                    duration: 0.5,
                    onComplete: () => {
                        this.currentIndex += 1;
                        if (this.currentIndex == this.animationItems.length)
                            this.currentIndex = 0;
                        this.startAnimation();
                    },
                });
            },
        });
    },
    onEnter({ currentTarget }) {
        this.pausedAnimation = true;
        const popup = currentTarget.getAttribute("data-popup");
        if (this.currentTween) {
            this.currentTween.kill();

            gsap.to(this.hoverLinkTargets, {
                opacity: 1,
                clear: "all",
            });
            gsap.to(this.animationItems, {
                opacity: (index, target) => {
                    const selected = target == this[`${popup}Target`];
                    return selected ? 1 : 0;
                },
                duration: 0.5,
            });
        } else {
            gsap.to(this[`${popup}Target`], {
                opacity: 1,
                duration: 0.5,
            });
        }
    },
    onLeave({ currentTarget }) {
        this.pausedAnimation = false;
        const popup = currentTarget.getAttribute("data-popup");

        gsap.to(this[`${popup}Target`], {
            opacity: 0,
            duration: 0.5,
            onComplete: () => {
                if (this.enableAnimations && window.innerWidth >= 1024)
                    this.startAnimation();
            },
        });
    },
    getFloatingItems() {
        return [
            this.artistTarget,
            this.artworkTarget,
            this.exhibitionTarget,
            this.publicationTarget,
        ];
    },
    onResize() {
        if (window.innerWidth >= 1024) {
            this.hoverLinkTargets.forEach((link) => {
                link.addEventListener("mouseenter", this.onEnter);
                link.addEventListener("mouseleave", this.onLeave);
            });

            if (this.enableAnimations) this.startAnimation();
            // ScrollSmoother.get()?.paused?.(true);
            body.lockScroll();
        } else {
            this.hoverLinkTargets.forEach((link) => {
                link.removeEventListener("mouseenter", this.onEnter);
                link.removeEventListener("mouseleave", this.onLeave);
            });

            this.currentTween?.kill?.();

            this.getFloatingItems().forEach((item) => (item.style.opacity = 1));
            // ScrollSmoother.get()?.paused?.(false);
            body.unlockScroll();
        }
    },
    stop() {
        this.hoverLinkTargets.forEach((link) => {
            link.removeEventListener("mouseenter", this.onEnter);
            link.removeEventListener("mouseleave", this.onLeave);
        });

        this.currentTween?.kill?.();
    },
};
