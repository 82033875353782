import gsap from "gsap";

export default {
    name: "collection-to-artwork",
    from: {
        namespace: ["collection"],
    },
    to: {
        namespace: ["artwork"],
    },
    // sync: true,
    leave(data) {
        const fromGallery = data.current.container
            .querySelector(".artworks-list")
            .classList.contains("hidden");
        data.next.container.classList.add("transitioning");
        gsap.set(data.next.container, { opacity: 0 });
        const onComplete = this.async();
        const isMobile = window.innerWidth < 1024;

        if (fromGallery || !isMobile) {
            // select the gallery item that fits the next URL
            const artworkImage = data.trigger.querySelector("img");
            const { top, left, width, height } =
                artworkImage.getBoundingClientRect();
            const floatingArtwork = artworkImage.cloneNode();
            floatingArtwork.classList.add("floating-artwork-clone");
            document.body.appendChild(floatingArtwork);
            gsap.set(floatingArtwork, {
                position: "fixed",
                left: 0,
                top: 0,
                scale: 1,
                transformOrigin: "top left",
                x: left,
                y: top,
                width,
                height,
                opacity: 1,
                zIndex: 5000,
            });

            setTimeout(() => {
                gsap.to(data.current.container, {
                    opacity: 0,
                    duration: 1,
                    delay: 0.5,
                    onComplete,
                });
            }, 0);
        } else {
            gsap.to(data.current.container, {
                opacity: 0,
                onComplete,
            });
        }
    },
    enter(data) {
        const isMobile = window.innerWidth < 1024;
        const fromGallery = data.current.container
            .querySelector(".artworks-list")
            .classList.contains("hidden");

        if (fromGallery || !isMobile) {
            const targetImage =
                data.next.container.querySelector(".artwork-image");
            const floatingArtwork = document.querySelector(
                ".floating-artwork-clone"
            );

            const targetWidth = targetImage.offsetWidth;
            const scale = targetWidth / floatingArtwork.offsetWidth;
            const targetHeight = targetImage.offsetHeight;

            gsap.to(floatingArtwork, {
                scale,
                x: window.innerWidth * 0.5 - targetWidth * 0.5,
                y: window.innerHeight * 0.9 * 0.5 - targetHeight * 0.5,
                ease: "expo.inOut",
                duration: 1,
                onComplete: () => {
                    gsap.to(data.next.container, {
                        opacity: 1,
                        onComplete: () => {
                            gsap.to(floatingArtwork, {
                                opacity: 0,
                                duration: 0.5,
                                onComplete: () => floatingArtwork.remove(),
                            });
                        },
                    });
                },
            });
        } else {
            gsap.to(data.next.container, {
                opacity: 1,
            });
        }
    },
};
