import xor from "lodash/xor";

export default {
    target: ["exhibition", "locations", "location", "year", "toggle"],
    start() {
        this.locationFilters = [];
    },
    onWindowClick(event) {
        this.toggleLocations(event);
    },
    toggleLocations(event) {
        event.stopPropagation();
        this.locationsTarget.classList.toggle("hidden");

        setTimeout(() => {
            if (!this.locationsTarget.classList.contains("hidden")) {
                window.addEventListener("click", this.onWindowClick);
                this.toggleTarget.innerHTML =
                    'Locations (<span class="font-alt font-black inline-flex items-center justify-center text-center w-3">−</span>)';
            } else {
                window.removeEventListener("click", this.onWindowClick);
                this.toggleTarget.innerHTML =
                    'Locations (<span class="font-alt font-black inline-flex items-center justify-center text-center w-3">+</span>)';
            }
        }, 0);
    },
    filterLocation(event) {
        event.stopPropagation();
        const location = event.currentTarget.getAttribute("data-id");
        // this.locationsTarget.classList.add("hidden");
        this.locationFilters = xor(this.locationFilters, [location]);

        this.updateLocations();
        this.updateItems();
    },
    updateLocations() {
        if (this.locationFilters.length) {
            this.locationTargets.forEach((location) => {
                const id = location.getAttribute("data-id");
                if (this.locationFilters.includes(id)) {
                    location.style.opacity = 1;
                } else {
                    location.style.opacity = 0.5;
                }
            });
        } else {
            this.locationTargets.forEach(
                (location) => (location.style.opacity = 1)
            );
        }
    },
    updateItems() {
        if (this.locationFilters.length) {
            this.exhibitionTargets.forEach((item) => {
                if (this.locationFilters.includes(item.dataset.location))
                    item.classList.remove("hidden");
                else item.classList.add("hidden");
            });

            this.yearTargets.forEach((year) => {
                const exhibits = [...year.querySelector(".exhibits").children];
                const yearContainsVisibleItems = exhibits.some(
                    (item) => !item.classList.contains("hidden")
                );

                if (yearContainsVisibleItems) {
                    year.classList.remove("hidden");
                } else {
                    year.classList.add("hidden");
                }
            });
        } else {
            this.exhibitionTargets.forEach((item) =>
                item.classList.remove("hidden")
            );

            this.yearTargets.forEach((year) => {
                year.classList.remove("hidden");
            });
        }
    },
    stop() {
        window.removeEventListener("click", this.onWindowClick);
    },
};
