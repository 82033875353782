export const constrain = (min, max, value) =>
    Math.max(min, Math.min(max, value));

export const getScrollBarWidth = () =>
    window.innerWidth - document.documentElement.clientWidth;
export const overviewPages = [
    "/about",
    "/collection",
    "/artists",
    "/exhibitions",
    "/journal",
    "/publications",
];

export const currentPageIsOverview = () => {
    const { pathname } = window.location;
    return overviewPages.includes(pathname);
};
