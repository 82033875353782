import { primaryInput } from "detect-it";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default {
    targets: ["image"],
    start() {
        if (this.imageTarget.complete) this.loaded();
    },
    loaded() {
        if (this.timeline) return;
        if (primaryInput == "touch" || window.innerWidth < 1024) return;

        this.init();
    },
    init() {
        this.timeline = gsap.timeline({
            scrollTrigger: {
                trigger: this.$el,
                start: "top top",
                end: "+=" + this.imageTarget.offsetHeight,
                pin: true,
                scrub: true,
            },
        });

        this.buildTimeline();

        ScrollTrigger.refresh();
    },
    buildTimeline() {
        const {
            x = 0.5,
            y = 0.5,
            width = 0.5,
            height = 0.5,
        } = this.$state.image.hotspot || {};

        const subsetArea =
            this.imageTarget.offsetWidth *
            width *
            (this.imageTarget.offsetHeight * height);
        const scale =
            (this.imageTarget.offsetWidth * this.imageTarget.offsetHeight) /
            subsetArea;

        const xPercent = -(50 + x * 100) * (scale * 0.5);
        const yPercent = -(50 + y * 100) * (scale * 0.5);

        this.timeline.fromTo(
            this.imageTarget,
            { scale: 1 },
            { scale: 1.35, ease: "power2.inOut" }
        );
    },
};
