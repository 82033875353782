import barba from "@barba/core";
import { primaryInput } from "detect-it";
import gsap from "gsap/all";
import body from "../lib/body";

export default {
    start() {
        this.isMobile = window.innerWidth < 1024;
        // this.isMobile = primaryInput == "touch" && window.innerWidth < 1024;
        this.buildPopup();
    },
    buildPopup() {
        const title = this.$state.title || this.$state.reference?.title;
        const description =
            this.$state.text || this.$state.reference?.description;
        this.popup = document.createElement("div");
        this.popup.className = "annotation-popup";
        this.popup.style.opacity = 0;

        this.popup.innerHTML = `
            <div class="annotation-toast">
                <div class="image-container aspect-thumbnail">
                    <img class="block w-full object-cover object-center" src="${
                        this.$state.imageUrl
                    }">
                </div>
                <div class="info mt-2">
                    <p class="${!title ? "hidden" : ""}">${title}</p>
                    <p class="${
                        !description ? "hidden" : ""
                    }">${description}</p>
                </div>
            </div>
        `;

        this.popup.addEventListener("click", this.onPopupClick);

        document.body.appendChild(this.popup);
    },
    show({ clientX }) {
        const { top, height } = this.$el.getBoundingClientRect();
        if (!this.isMobile) {
            gsap.set(this.popup, {
                x: Math.max(clientX - this.popup.offsetWidth - 24, 24),
                y: top + height + 24,
            });
        } else {
            body.lockScroll();
        }

        gsap.to(this.popup, {
            opacity: 1,
            onComplete: () => {
                if (this.isMobile) this.popup.style.pointerEvents = "auto";
            },
        });
    },
    hide() {
        body.unlockScroll();
        this.popup.style.pointerEvents = "none";
        gsap.to(this.popup, {
            opacity: 0,
        });
    },
    onMouseEnter(e) {
        if (!this.isMobile) {
            this.show(e);
        }
    },
    onClick(event) {
        event.preventDefault();
        if (this.isMobile) {
            // We assume we are on phone or touch device without hover
            // show popup
            this.show(event);
        } else {
            if (this.$state.reference) {
                barba.go(this.$state.reference.slug.current);
                this.hide();
            }
        }
    },
    onPopupClick(event) {
        if (this.isMobile) {
            if (event.target != this.popup && this.$state.reference)
                barba.go(this.$state.reference.slug.current);
            this.hide();
        }
    },
    onMouseLeave() {
        if (!this.isMobile) {
            this.hide();
        }
    },
    stop() {
        this.popup.remove();
    },
};
