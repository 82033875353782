import ScrollTrigger from "gsap/ScrollTrigger";

export default {
    targets: ["form", "email", "name", "type", "company", "submit", "feedback"],
    start() {},
    onSubmit(event) {
        event.preventDefault();

        return this.submit();
    },
    async submit() {
        if (this.isFormValid()) {
            const formData = new FormData(this.formTarget);
            const body = {};

            for (let key of formData.keys()) {
                body[key] = formData.get(key);
            }

            try {
                this.submitTarget.disabled = true;
                this.formTarget.classList.add("opacity-40");
                const response = await fetch("/api/newsletter", {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.status == 200) {
                    this.formTarget.classList.add("hidden");
                    this.feedbackTarget.classList.remove("hidden");
                }
            } catch (e) {
                this.submitTarget.disabled = false;
                this.formTarget.classList.remove("opacity-40");
            }
        }
    },
    emailChange() {
        if (this.emailTarget.validity.valid && !this.isShowingFields) {
            this.isShowingFields = true;
            const fieldsToShow = [this.nameTarget, this.typeTarget];
            fieldsToShow.forEach((t) => t.classList.remove("hidden"));
        }
    },
    typeChange() {
        if (["professional", "press"].includes(this.typeTarget.value)) {
            this.showCompany();
        } else {
            this.hideCompany();
        }
    },
    formChange() {
        if (this.isFormValid()) {
            this.submitTarget.disabled = false;
        } else {
            this.submitTarget.disabled = true;
        }
    },
    isFormValid() {
        let valid = true;

        if (
            !this.nameTarget.validity.valid ||
            !this.typeTarget.value ||
            this.typeTarget.value == "" ||
            !this.emailTarget.validity.valid
        )
            valid = false;

        if (this.companyTarget && !this.companyTarget.validity.valid)
            valid = false;

        return valid;
    },
    showName() {
        this.hasName = true;
        // <input type="text" name="name" class="block mt-4 w-full leading-[1.4] text-xl py-1 border-style border-b hidden" data-newsletter-target="name" placeholder="What's your name?"/>
        const input = document.createElement("input");
        input.required = true;
        input.name = "name";
        input.type = "text";
        input.setAttribute("data-newsletter-target", "name");
        input.placeholder = "What's your name?";
        input.className =
            "block mt-4 w-full leading-[1.4] text-xl py-1 border-style border-b";
    },
    showCompany() {
        if (this.companyTarget) return;

        this.hasType = true;
        const input = document.createElement("input");
        input.required = true;
        input.name = "company";
        input.type = "text";
        input.setAttribute("data-newsletter-target", "company");
        input.placeholder = "Where do you work?";
        input.className =
            "block mt-4 w-full leading-[1.4] text-xl py-1 border-style border-b border-white focus:border-black focus:outline-none";
        this.formTarget.insertBefore(input, this.submitTarget.parentNode);
        ScrollTrigger.refresh();
    },
    hideCompany() {
        this.companyTarget?.remove?.();
        ScrollTrigger.refresh();
    },
    stop() {},
};
