import { primaryInput } from "detect-it";
import Flickity from "flickity";
import ScrollTrigger from "gsap/ScrollTrigger";
import polylinearScale from "polylinear-scale";
import barba from "@barba/core";
import gsap from "gsap";

export default {
    targets: ["slide", "slider", "slideImage", "leftButton", "rightButton"],
    start() {
        this.started = false;
        this.slider = new Flickity(this.sliderTarget, {
            dragThreshold: 15,
            cellAlign: "left",
            pageDots: false,
            prevNextButtons: false,
            resize: true,
            draggable: primaryInput == "touch",
            selectedAttraction: 0.015,
        });

        this.slider.on("scroll", this.onSliderScroll);
        this.slider.on("resize", this.createScales);
        this.slider.on("change", this.onSlideChange);
        this.slider.on("staticClick", this.onStaticClick);

        this.sliderTarget.addEventListener("touchmove", this.onTouchMove);
        this.sliderTarget.addEventListener("touchend", this.onTouchEnd);

        this.createScales();
        this.onSliderScroll();
        this.onSlideChange();

        ScrollTrigger.refresh();
    },
    onTouchMove() {
        this.dragged = true;
    },
    onTouchEnd() {
        setTimeout(() => {
            this.dragged = false;
        });
    },
    createScales() {
        this.slider.slides.forEach((slide, i) => {
            slide.translateScale = polylinearScale(
                [-slide.outerWidth, 0, slide.outerWidth],
                [-1, 0, 1],
                true
            );
            slide.setter = gsap.quickSetter(
                this.slideImageTargets[i],
                "x",
                "px"
            );
            slide.textSetter = gsap.quickSetter(
                this.slideTextTargets[i],
                "x",
                "px"
            );
            slide.textOpacitySetter = gsap.quickSetter(
                this.slideTextTargets[i],
                "opacity"
            );
        });
    },
    slideTap(event) {
        event.preventDefault();
    },
    onSliderScroll(event) {
        this.slider.slides.forEach((slide, i) => {
            const translateX = slide.translateScale(
                this.slider.x + slide.target
            );
            const displacement =
                window.innerWidth >= 1024
                    ? -window.innerWidth * 0.45
                    : -window.innerWidth * 0.3;
            // this.slideImageTargets[i].style.transform = `translateX(${
            //     displacement * translateX
            // }px)`;
            slide.setter(displacement * translateX);

            const textOpacity = 1 - Math.abs(translateX);
            slide.textOpacitySetter(textOpacity);
            if (window.innerWidth >= 768) {
                const textDisplacement = displacement * 0.75 * translateX;
                slide.textSetter(textDisplacement);
            } else {
                slide.textSetter(0);
            }

            // this.slideTextTargets[i].style.transform = `translateX(${
            //     -window.innerWidth * 0.33 * translateX
            // }px)`;
        });
    },
    slideClick(event) {
        // event.preventDefault();
        const slide = event.currentTarget;

        if (slide == this.slider.selectedElement) {
            barba.go(slide.querySelector("a").href);
        } else {
            // event.preventDefault();
            const cursor = slide.getAttribute("data-cursor");
            if (cursor == "leftArrow") this.slider.previous();
            else this.slider.next();
        }
    },
    onStaticClick(event, pointer, cellElement) {
        event.preventDefault();
        if (this.dragged) return;

        const slide = cellElement;
        if (slide == this.slider.selectedElement) {
            barba.go(slide.querySelector("a").href);
        } else {
            const cursor = slide.getAttribute("data-cursor");
            if (cursor == "leftArrow") this.slider.previous();
            else this.slider.next();
        }
    },
    onSlideChange() {
        const isFirst = this.slider.selectedIndex == 0;
        const isLast =
            this.slider.selectedIndex == this.slider.slides.length - 1;

        const enableClass = ["pointer-events-auto"];

        if (isFirst) this.leftButtonTarget.classList.remove(enableClass);
        else this.leftButtonTarget.classList.add(enableClass);

        if (isLast) this.rightButtonTarget.classList.remove(enableClass);
        else this.rightButtonTarget.classList.add(enableClass);

        this.slider.cells.forEach(({ element }) => {
            let cursor = null;
            if (element == this.slider.selectedElement) {
                cursor = "plus";
            }

            element.setAttribute("data-cursor", cursor);
        });
    },
    nextClick() {
        this.slider.next();
    },
    prevClick() {
        this.slider.previous();
    },
    stop() {
        this.slider.destroy();
    },
};
