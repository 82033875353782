import gsap from "gsap";

export default {
    targets: ["image"],
    start() {
        this.setX = gsap.quickSetter(this.imageTarget, "x", "px");
        this.toX = gsap.quickTo(this.imageTarget, "x", {
            duration: 0.5,
            ease: "power3.out",
        });
        this.toY = gsap.quickTo(this.imageTarget, "y", {
            duration: 0.5,
            ease: "power3.out",
        });
        this.$el.addEventListener("mouseenter", this.onMouseEnter);
    },
    onMouseEnter(event) {
        this.setX(event.pageX);
        gsap.set(this.imageTarget, { opacity: 1 });
        window.addEventListener("mousemove", this.onMouseMove);
        this.$el.addEventListener("mouseleave", this.onMouseLeave);

        this.onMouseMove(event);
    },
    onMouseMove(event) {
        this.setX(event.pageX);
    },
    onMouseLeave() {
        gsap.set(this.imageTarget, { opacity: 0 });
        window.removeEventListener("mousemove", this.onMouseMove);
        this.$el.removeEventListener("mouseleave", this.onMouseLeave);
    },
    onClick() {
        this.$el.removeEventListener("mouseleave", this.onMouseLeave);
        window.removeEventListener("mousemove", this.onMouseMove);
        this.$el.removeEventListener("mouseenter", this.onMouseEnter);
    },
    stop() {
        this.$el.removeEventListener("mouseleave", this.onMouseLeave);
    },
};
