import gsap from "gsap";

export default {
    targets: ["list", "grid"],
    start() {},
    toggleGrid() {
        gsap.to(this.gridTarget, { opacity: 1 });
        gsap.to(this.listTarget, { opacity: 0.5 });
        this.$events.emit("collection:toggleGrid");
    },
    toggleList() {
        gsap.to(this.gridTarget, { opacity: 0.5 });
        gsap.to(this.listTarget, { opacity: 1 });
        this.$events.emit("collection:toggleList");
    },
};
